import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './service/axios'
import VueI18n from 'vue-i18n'
import en from '@/assets/locales/en.json'
import fr from '@/assets/locales/fr.json'

Vue.config.productionTip = false
Vue.prototype.$axios = axios

Vue.use(VueI18n)
const messages = {
  en: { message: en },
  fr: { message: fr }
}

const i18n = new VueI18n({
  locale: 'en',
  messages
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
