<template>
  <div id="app">
    <router-view/>
    <app-loader v-if="$store.getters.showLoader"></app-loader>
  </div>
</template>
<script>
import AppLoader from './components/AppLoader.vue'
import autoGrow from './mixins/autogrow'
export default {
  components: { AppLoader },
  mixins: [autoGrow],
  mounted () {
    const self = this
    window.addEventListener('message', function (event) {
      if (event.origin !== self.$store.getters.domain) {
        return
      }
      if (event.data.event) {
        if (event.data.event === 'p2mResize') {
          self.sendResizeContent(false, false)
        } else if (event.data.event === 'initialize') {
          self.$store.dispatch('setIframeId', event.data.iFrameId)
          self.sendResizeContent(true, true)
        }
      }
    }, false)
  }
}
</script>
<style lang="scss">
@import url('./assets/index.scss');
</style>
