export default {
  data () {
    return {
      documentScrollHeight: null,
      scrollHeightCheckInterval: null
    }
  },
  methods: {
    sendResizeContent (isInitialized, forceScrollToTop) {
      const iframeId = this.$store.getters.parentIframeId
      const allowAutoGrow = this.$store.getters.allowAutoGrow
      if (isInitialized === undefined) {
        isInitialized = false
      }
      if (iframeId !== null && allowAutoGrow) {
        if (forceScrollToTop) {
          if (!this.$store.getters.emitScrollToTop) {
            forceScrollToTop = false
          }
        } else {
          forceScrollToTop = false
        }
        if (iframeId) isInitialized = true
        const payload = {
          event: 'resizeContent',
          params: {
            height: document.body.scrollHeight + 'px',
            width: document.body.scrollWidth + 'px',
            iFrameId: iframeId,
            scrollToTop: forceScrollToTop,
            isInitialized: isInitialized
          }
        }
        window.parent.postMessage(payload, '*')
      }
    },
    requestInitialPostMessage () {
      const payload = {
        event: 'initPoll'
      }
      window.parent.postMessage(payload, '*')
    }
  }
}
